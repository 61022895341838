import {IAdmissionDateProcessed} from '@Features/activityAdmissionDates/types';
import {DateType} from '@Utils/dates/timezoneDates';
import {
  EPassLayout,
  EPassType,
  IActivityVariant,
  IAddition,
  IAdmissionPolicy,
  IBookingInformation,
  IFacilityTag,
  IQuestion,
  ISaleInformations,
  IActivitySalePolicy,
  IAdmissionDate,
  TOrderType,
  EShippingMethodsRequirement
} from './apiGlobals';
import {IBasketItem} from './types';

export interface ISalePolicyProcessed extends Omit<IActivitySalePolicy, 'showEndDate'> {
  showEndDate: DateType | null
}

export interface IAdmissionPolicyProcessed extends
  Omit<IAdmissionPolicy, 'startDatetime' | 'endDatetime' | 'firstAdmissionDate'> {
    startDatetime: DateType | null,
    endDatetime: DateType | null,
    firstAdmissionDate: string | null,
    dates: IAdmissionDate[]
}

export interface IActivityVariantProcessed extends IActivityVariant {
  dates: IAdmissionDateProcessed[],
  questions: IQuestion[]
}

export interface IActivityProcessed {
  id: number
  name: string
  bookingInformation: IBookingInformation
  description: string | null
  addressDescription: string | null
  variants: IActivityVariantProcessed[]
  variantsDescription: string | null
  salePolicy: ISalePolicyProcessed
  coverPhoto: string | null
  priority: number
  passType: EPassType
  passLayout: EPassLayout
  admissionPolicy: IAdmissionPolicyProcessed
  shippingMethodRequirement: EShippingMethodsRequirement | null
  facilityTags: IFacilityTag[] | []
  saleInformations: ISaleInformations[]
  questions?: IQuestion[]
  additions?: IAddition[]
}

export enum ELocale {
  PL = 'pl',
  EN = 'en',
  ES = 'es',
  SK = 'sk',
  CS = 'cs',
  DE = 'de',
  IT = 'it',
  UK = 'uk',
  HU = 'hu',
}

export enum EWidgetType {
  ONLINE_CLIENT = 'ONLINE_CLIENT',
  ONLINE_CLIENT_PREVIEW = 'ONLINE_CLIENT_PREVIEW',
  BUSINESS_CLIENT = 'BUSINESS_CLIENT',
  BUSINESS_CLIENT_PREVIEW = 'BUSINESS_CLIENT_PREVIEW',
  INTERNAL_CLIENT = 'INTERNAL_CLIENT',
  INTERNAL_CLIENT_PREVIEW = 'INTERNAL_CLIENT_PREVIEW'
}

export type TLatestOrderStorageData = {
  facilityId: number,
  orderType: TOrderType,
  code?: string,
  orderId?: string,
  isEventsSent?: boolean,
  onlineGroupId: number | null,
  businessGroupId?: number,
  widgetType: EWidgetType,
  isPreviewMode: boolean,
  basket?: IBasketItem[],
  email?: string,
  timestamp?: number
}
