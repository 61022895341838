import {VerticalAlignment} from './hooks/useScroll';

type messageData = {
  name: string,
  iframeElementYCord: number,
  verticalAlignment: VerticalAlignment
}

const minimumScrollValue = 50;

export function getScrollData(iframeElement: Element, iframeTop: number, messageData: messageData) {
  const elementTop = messageData.iframeElementYCord + iframeTop;
  const offset = messageData.verticalAlignment === VerticalAlignment.CENTER ?
    elementTop - (window.innerHeight / 2) : elementTop;
  const scrollDistance = window.scrollY - Math.abs(offset);

  if (iframeElement && Math.abs(scrollDistance) > minimumScrollValue) {
    return {
      scroll: true,
      offset
    };
  }
  return {
    scroll: false
  };
}

