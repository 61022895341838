import {IAddition, IQuestion} from '@Consts/apiGlobals';
import {EStatus} from '@Consts/status';
import {EUpsellActionsTypes, TUpsellActions} from './types';
import {ThunkAction} from 'redux-thunk';
import {IStore} from 'src/js/store/types';
import {AnyAction} from 'redux';
import {apiGet} from '@Utils/api/api';
import {getConfiguration, getFacilityId, getOnlineGroupId} from '@Features/configuration/configurationSelectors';
import {getProcessedActivities} from '@Features/activities/activitiesUtils';
import {IActivityProcessed} from '@Consts/globals';
import {appendAffiliationHash, getUrlIds} from '@Utils/api/apiUtils';
import locale from '@Utils/locale';
import {UpsellActivitiesPath, UpsellActivitiesQueryParameters} from '@Utils/api/types';

export const setUpsellStatus = (status: EStatus): TUpsellActions => ({
  type: EUpsellActionsTypes.SET_UPSELL_STATUS,
  payload: status
});

export const setUpsellActivities = (upsellActivities: IActivityProcessed[]): TUpsellActions => ({
  type: EUpsellActionsTypes.SET_UPSELL_ACTIVITIES,
  payload: upsellActivities
});

export const setPickedUpsellActivity = (pickedUpsellActivity: IActivityProcessed): TUpsellActions => ({
  type: EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITY,
  payload: pickedUpsellActivity
});

export const setUpsellActivityAdditions = (activityId: number, additions: IAddition[]): TUpsellActions => ({
  type: EUpsellActionsTypes.SET_UPSELL_ACTIVITIES_ADDITONS,
  payload: {activityId, additions}
});

export const setUpsellActivityQuestions = (activityId: number, questions: IQuestion[]): TUpsellActions => ({
  type: EUpsellActionsTypes.SET_UPSELL_ACTIVITIES_QUESTIONS,
  payload: {activityId, questions}
});

export const setPickedUpsellActivityAdditions = (activityId: number, additions: IAddition[]): TUpsellActions => ({
  type: EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITIES_ADDITONS,
  payload: {activityId, additions}
});

export const setPickedUpsellActivityQuestions = (activityId: number, questions: IQuestion[]): TUpsellActions => ({
  type: EUpsellActionsTypes.SET_PICKED_UPSELL_ACTIVITIES_QUESTIONS,
  payload: {activityId, questions}
});

export const fetchUpsellOffer = (activititesVariantIds: number[]): ThunkAction<
void,
IStore,
unknown,
AnyAction
> => async (dispatch, getState) => {
  const facilityId = getFacilityId(getState());
  const onlineGroupId = getOnlineGroupId(getState());

  if (!facilityId || !onlineGroupId) {
    return;
  }

  const urlIds = getUrlIds(activititesVariantIds);

  const config = getConfiguration(getState());
  const {affiliationHash} = config;
  const userLanguage = locale.locale;
  const urlPath: UpsellActivitiesPath =
    `user-api/facilities/${facilityId}/online_groups/${onlineGroupId}/activities/automatic_upsell`;
  const urlQueryParams: UpsellActivitiesQueryParameters = `?id=${urlIds}&locale=${userLanguage}`;

  const url = appendAffiliationHash(
    affiliationHash,
    `${urlPath}${urlQueryParams}`
  );

  dispatch(setUpsellStatus(EStatus.IN_PROGRESS));

  try {
    const upsellActivities = await apiGet(url, config);

    const processedUpsellActivities = getProcessedActivities(upsellActivities, true);

    dispatch(setUpsellActivities(processedUpsellActivities));
    dispatch(setUpsellStatus(EStatus.SUCCESS));

  } catch (error) {
    dispatch(setUpsellStatus(EStatus.FAILURE));

    throw error;
  }
};
