import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import locale from '@Utils/locale';
import ActivityItemContent from './ActivityItemContent';
import ActivityVariants from './ActivityVariants';
import {getBasketActivityItems} from '@Features/basket/basketSelectors';
import {useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import {hasActivityValidPrice} from '@Utils/activity/activity';

const getVariantPickerStyles = {
  height: '46px',
  width: '134px',
  fontWeight: '700'
};

const ActivityItemWithVariants = ({activity, indicated, orderNumber, numberOfActivities}) => {
  const activityBasketItems = useSelector(state => getBasketActivityItems(state, activity.id));
  const activityHaveValidPrices = hasActivityValidPrice(activity.variants);
  const [areVariantsVisible, setVariantsVisibility] = useState(
    (Boolean(activityBasketItems.length) ||
    indicated ||
    numberOfActivities === 1) &&
    activityHaveValidPrices
  );
  const [disableEffect, setDisableEffect] = useState(false);

  useEffect(() => {
    if (!areVariantsVisible && !disableEffect) {
      setVariantsVisibility(Boolean(activityBasketItems.length));
    }
  }, [activityBasketItems]);

  function handleVariantsToggle() {
    setDisableEffect(true);
    setVariantsVisibility(!areVariantsVisible);
  }

  return (
    <ActivityItemContent
      activity={activity}
      indicated={indicated}
      numberOfActivities={numberOfActivities}
      picker={(
        <Button
          onClick={handleVariantsToggle}
          variant={areVariantsVisible ? 'outlined' : 'contained'}
          sx={getVariantPickerStyles}
        >
          {locale.translate(areVariantsVisible ? 'hide' : 'choose')}
        </Button>
      )}
      additionalContent={
        areVariantsVisible ? (
          <ActivityVariants
            activity={activity}
            orderNumber={orderNumber}
          />
        ) : null
      }
    />
  );
};

ActivityItemWithVariants.propTypes = {
  activity: PropTypes.object.isRequired,
  orderNumber: PropTypes.number.isRequired,
  indicated: PropTypes.bool,
  numberOfActivities: PropTypes.number.isRequired
};

export default ActivityItemWithVariants;
