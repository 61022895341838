import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import ActivityNearestDate from './ActivityNearestDate';
import {EPassType} from '@Consts/apiGlobals';

type Props = {
  name: string;
  variantName: string | boolean;
  isValidPrices: boolean;
  activityLabelText: string;
  isDesktop: boolean;
  isUpsell: boolean;
  firstAdmissionDate?: string;
  passType: EPassType;
  isOneAdmissionDateAvailable: boolean
}

const ActivityHeader = ({
  name,
  variantName,
  isValidPrices,
  activityLabelText,
  isDesktop,
  isUpsell,
  firstAdmissionDate,
  passType,
  isOneAdmissionDateAvailable
}: Props) => {
  const activityClass = isUpsell ? 'dl-activity-upsell' : 'dl-activity';

  return (
    <div className="dl-header">
      {
        (isValidPrices && firstAdmissionDate && !isOneAdmissionDateAvailable) &&
        <ActivityNearestDate firstAdmissionDate={firstAdmissionDate} passType={passType} />
      }
      {name}{variantName ? `, ${variantName}` : ''}

      {
        activityLabelText && (!isDesktop || isUpsell) && (
          <span className={`${activityClass}__text-activity-label`}>
            <span> • </span>
            <a className={`${activityClass}__text-activity-label__label`}>
              {activityLabelText}
            </a>
          </span>
        )
      }
    </div>
  );
};

ActivityHeader.propTypes = {
  name: PropTypes.string.isRequired,
  variantName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  activityLabelText: PropTypes.string,
  isDesktop: PropTypes.bool
};

export default ActivityHeader;
