import {COLON_HOUR_FORMAT} from '@Utils/dayjs/const';
import {getFormattedDate, getTimezoneDate, isSameDate} from '@Utils/dayjs/dayjsUtils';
import {getFormattedPrice} from '@Utils/format';
import locale from '@Utils/locale';
import {Dayjs} from 'dayjs';
import {Option} from './types';

export const getTimePickerItemDetails = (
  option: Option, canShowItemsLeft: boolean, isShowPrices: boolean, timezone: string, selectedTime?: Dayjs) => {
  const {
    currency,
    date,
    isOutOfLimit,
    numberOfSeats,
    totalPrice,
    isBargainPrice
  } = option;

  const dateWithTimezone = getTimezoneDate(date, timezone);
  const isSoldOut = isOutOfLimit && numberOfSeats === 0;
  const hasNoSeats = isOutOfLimit && numberOfSeats > 0;
  const isDisabled = isSoldOut || hasNoSeats;
  const textColor = isDisabled ? 'text.disabled' : 'inherit';
  const showAvailableSeats = canShowItemsLeft && !isSoldOut && !hasNoSeats;
  const showPrice = isShowPrices && !isSoldOut && !hasNoSeats;
  const textSeats = `${numberOfSeats > 999 ? '999+' : numberOfSeats} ${locale.translate('seats')}`;
  const textPrice = `${getFormattedPrice(totalPrice)} ${locale.translate(currency)}`;
  const textTime = getFormattedDate(dateWithTimezone, COLON_HOUR_FORMAT);
  const selectedTimeWithTimezone = getTimezoneDate(selectedTime || null, timezone);
  const selectedTimeSameAsDate = isSameDate(selectedTimeWithTimezone, dateWithTimezone, 'minute');
  const isSelected = selectedTime && selectedTimeSameAsDate;

  return {
    textTime,
    textPrice,
    textSeats,
    showPrice,
    showAvailableSeats,
    textColor,
    isDisabled,
    hasNoSeats,
    isSoldOut,
    isBargainPrice: isBargainPrice ?? false,
    isSelected: !!isSelected
  };
};

