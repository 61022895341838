import CalendarSectionHeader from '@Components/calendar/CalendarSectionHeader';
import {EPassType} from '@Consts/apiGlobals';
import {IBasketItemSelections} from '@Consts/types';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import {isActivitySinglePickDate} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import React from 'react';

type Props = {
    selections: IBasketItemSelections
    passType: EPassType
}

const AdmissionSummary = ({selections, passType}: Props) => {
  const {time: selectedTime, dates: selectedDates} = selections;
  const selectedTimeDescription = selectedTime?.description;
  const selectedDatesDescription = isActivitySinglePickDate(passType) &&
  selectedDates?.[0]?.group[0]?.description;

  return (
    <Box>
      <CalendarSectionHeader text={locale.translate('selected')} />
      {
        (!!selectedTimeDescription || !!selectedDatesDescription) && (
          <Alert severity="warning" sx={{mb: 1}}>
            {selectedTimeDescription || selectedDatesDescription}
          </Alert>
        )
      }
    </Box>
  );
};

export default AdmissionSummary;
