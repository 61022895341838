import React from 'react';
import {Dayjs} from 'dayjs';
import {CalendarCellData} from '@Utils/hooks/useCalendarCell';
import Box from '@mui/material/Box';
import {
  TextAvailableSeats,
  TextDay,
  TextFrom,
  TextPrice,
  TextSoldOutOrNoSeats,
  TextWithoutDetails
} from './CalendarComponents';
import locale from '@Utils/locale';
import {getFormattedPrice} from '@Utils/format';

type Props = {
    calendarDay: Dayjs;
    isDisabled: boolean;
    data: CalendarCellData;
    showAvailableSeatsForDay: boolean;
    showPrices: boolean;
}

const CalendarCellDetails = ({calendarDay, isDisabled, data, showAvailableSeatsForDay, showPrices}: Props) => {
  const isWithAllAdditionalInformation = showAvailableSeatsForDay && showPrices;
  const availableSeats = data?.availableSeats;
  const isAvailableSeats = showAvailableSeatsForDay && !!availableSeats;
  const isWithoutDetails = (showAvailableSeatsForDay || showPrices) && isDisabled && !data;

  return (
    <Box>

      <TextDay isDisabled={isDisabled} isToday={!!data?.isToday} isPriceNotFixed={!!data?.isPriceNotFixed}>
        {calendarDay.date()}
      </TextDay>

      {
        data?.showPrices && (
          <TextPrice
            isBargainPrice={data?.isBargainPrice}
          >
            {!!data?.isPriceNotFixed && (
              <>
                <TextFrom component="span">
                  {`${locale.translate('from')} `.toLowerCase()}
                </TextFrom>
                <br />
              </>
            )}
            {getFormattedPrice(data?.totalPrice)}&nbsp;{locale.translate(`${data?.currency}calendar`)}
          </TextPrice>
        )
      }
      {
        !data?.hasNoSeats && isAvailableSeats && (
          <TextAvailableSeats id="seats" isDisabled={isDisabled}>
            {availableSeats > 999 ? '999+' : availableSeats} {locale.translate('seats')}
          </TextAvailableSeats>
        )
      }

      {
        isWithoutDetails && (
          <TextWithoutDetails isWithAllAdditionalInformation={isWithAllAdditionalInformation}>
            –
          </TextWithoutDetails>
        )
      }

      {
        data?.isSoldOut && (
          <Box sx={{mt: {xs: isWithAllAdditionalInformation ? 2 : 0, sm: isWithAllAdditionalInformation ? 3 : 0}}}>
            <TextSoldOutOrNoSeats>
              {locale.translate('isSoldOut')}
            </TextSoldOutOrNoSeats>
          </Box>
        )
      }

      {
        data?.hasNoSeats && (
          <Box sx={{mt: {xs: isWithAllAdditionalInformation ? 2 : 0, sm: isWithAllAdditionalInformation ? 3 : 0}}}>
            <TextSoldOutOrNoSeats>
              {locale.translate('hasNoSeats')}
            </TextSoldOutOrNoSeats>
          </Box>
        )
      }
    </Box>
  );
};

export default CalendarCellDetails;
