/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'L\'intervallo selezionato contiene termini non disponibili',
  'PLN': 'PLN',
  'EUR': 'EUR',
  'PLNcalendar': 'zł',
  'EURcalendar': '€',
  'validPostalCodeLength': 'Inserisci un codice postale valido (es. 00-000)',
  'minLength': 'Numero minimo di caratteri: %{length}',
  'maxLength': 'Numero massimo di caratteri: %{length}',
  'parcelLockerSelected': 'Armadietto pacchi selezionato:',
  'selectParcelLocker': 'Selezionare l\'armadietto dei pacchi',
  'selectAnotherParcelLocker': 'Selezionare un altro armadietto dei pacchi',
  'parcelLockerPointError': 'Impossibile ottenere l\'indirizzo corretto dell\'armadietto dei pacchi',
  'enterDeliveryPhoneNumber': 'Inserire il numero di telefono per la consegna',
  'onlineMethodsDescription': 'Per altri servizi, consegna elettronica.',
  'shippingMethodRequired': 'Prima di procedere al pagamento, è necessario selezionare un metodo di spedizione.',
  'chooseShippingMethod': 'Scegliere il metodo di spedizione',
  'generateWithAmount': 'Generare (%{amountToPay})',
  'additionsHeader': 'Aggiunte: %{activityName}',
  'additionsLimitReached': 'Il limite delle aggiunte è stato raggiunto',
  'perPersonNumber': '%{number} pers. x',
  'selectedDayIsNotAvailable': 'La data %{date} non è disponibile',
  'selectedDateIsNotAvailable': 'La data %{date} non è disponibile',
  'errorOccurredWhileFetchingOrder': 'Non possiamo caricare il suo ordine. Se fai il pagamento, per favore controlla la tua casella di posta.',
  'priceTooHigh': 'Il valore dei servizi selezionati è troppo alto. Non è possibile procedere al pagamento.',
  'invalidNip': 'Il numero di partita IVA non è corretto',
  'orderCreationError': 'Errore di creazione dell\'ordine - riprova',
  'activitiesFetchError': 'Impossibile caricare le attività della struttura',
  'chooseLimitReached': 'Raggiunto il limite di pezzi selezionati',
  'localTours': 'Visite locali',
  'clauseAcceptanceRequired': 'Clausola di accettazione richiesta',
  'invalidPostalCode': 'Codice postale non valido',
  'invalidNIPLength': 'Il ID Fiscale dell\'azienda ha una lunghezza non valida',
  'invalidPhone': 'Numero di telefono non valido',
  'invalidEmail': 'Indirizzo e-mail non valido',
  'invalidFormatError': 'Formato non valido',
  'toLongError': 'Il numero massimo di caratteri è 30',
  'toShortError': 'Il numero minimo di caratteri è 3',
  'invalidTypeError': 'Valore non valido',
  'missingFieldError': 'Impossibile estrarre il valore da questo campo',
  'unknownError': 'Errore sconosciuto',
  'errorScriptNotFound': 'Errore - il tuo browser non è supportato o la configurazione del negozio non è valida',
  'addressRequired': 'Indirizzo richiesto',
  'regulationAcceptRequired': 'È richiesta l\'accettazione dei Termini e delle Norme sulla Privacy',
  'regulationsAcceptRequired': 'È richiesta l\'accettazione dei Termini e delle Norme sulla Privacy',
  'phoneRequired': 'Numero di telefono richiesto',
  'lastNameRequired': 'Cognome richiesto',
  'firstNameRequired': 'Nome richiesto',
  'emailRequired': 'Indirizzo e-mail richiesto',
  'answerRequired': 'Risposta richiesta',
  'skippTheLine': 'Salta la riga del biglietto',
  'downloadInPreviewIsDisabled': 'In modalità anteprima l\'ordine viene inviato in e-mail e non può essere scaricato da questa vista',
  'noValidDatesRange': 'Nessun intervallo di date valido disponibile',
  'chooseNumberOfDays': 'Scegli il numero di giorni',
  'day': 'giorno',
  'perPersonAbbr': 'pp',
  'maxNumberOfParticipants': 'Il numero massimo di partecipanti è %{number}',
  'minNumberOfParticipants': 'Il numero minimo di partecipanti è %{number}',
  'enterNumberOfParticipants': 'Inserisci il numero di partecipanti',
  'numberOfParticipants': 'Numero di partecipanti',
  'numberOfParticipantsBetween': 'Numero di partecipanti: da %{min} a %{max}',
  'generate': 'Generare',
  'seats': 'pz.',
  'attention': 'Attenzione!',
  'forSinglePerson': 'per 1 persona',
  'chooseAdditions': 'Scegliere le aggiunte',
  'pleaseChooseOneOption': 'La scelta di un\'opzione è necessaria',
  'or': 'o',
  'addToAppleWallet': 'Aggiungi al portafoglio Apple',
  'realiseOrder': 'Realizzare l\'ordine',
  'generatingPdf': 'Il PDF sta generando...',
  'transactionCompletedSuccessfully': 'Transazione completata con successo!',
  'immediatelyProceedThroughPayment': 'Una volta confermato il pagamento, i biglietti verranno caricati di seguito e inviati a %p',
  'optionally': 'opzionale',
  'shopOnline': 'Negozio online',
  'orderOnline': 'Ordina online',
  'discountsAreDisabledInPreview': 'Gli sconti sono disabilitati in anteprima',
  'contactNumberToClient': 'Numero di contatto del cliente che realizzerà l\'attività - raccolto solo per le esigenze della sua realizzazione, ad esempio la necessità di contatto in caso di situazione casuale.',
  'numberOnlyForRealisation': 'Numero solo per la realizzazione dell\'attività',
  'unsuccessfullyOrderedSending': 'L\'invio della conferma d\'ordine non ha avuto successo al %{email}. Riprovare più tardi o inviare un messaggio a pomoc@droplabs.pl',
  'successfullyOrderedSending': 'Invio riuscito della conferma al %{email}',
  'requiredField': 'Campo richiesto',
  'forSendingAcceptTos': 'Per l\'invio, il cliente accetta',
  'agreementFormWithClause': 'Modulo di accordo con clausola informativa',
  'send': 'Invia',
  'downloadToPrint': 'Scaricare per stampare',
  'clientEmail': 'Indirizzo e-mail del cliente',
  'chooseSendingConfirmationMethod': 'Pronto! Scegli come fornire la conferma al cliente',
  'orderPreviewInfo': 'L\'ordine campione è stato inviato all\'e-mail: %p. Se non vedi il messaggio entro pochi minuti dal pagamento, controlla la tua cartella spam. Se non funziona, inserisci "Droplabs" nel motore di ricerca del tuo programma di posta elettronica".',
  'exceededTimeout': 'Superamento del tempo di attesa del pagamento',
  'buyerWillSettleWithYou': 'La persona che utilizzerà i servizi del %p si accorderà con te',
  'youCanPrintTickets': 'Dopo l\'acquisto, puoi stampare i tuoi biglietti pronti all\'uso. Non ci sarà un prezzo ai biglietti',
  'onlinePaymentForOrder': 'Si paga l\'ordine online. Una fattura sarà emessa da %p per la vostra azienda per un periodo stabilito, di solito collettivamente per tutto il mese.',
  'youWillPayForOrderBasedOnInvoice': 'Pagherai l\'ordine sulla base di una fattura, che sarà emessa dalla %p per la tua azienda per un periodo stabilito, di solito collettivamente per l\'intero mese.',
  'wholeAmountWillBeChargedForUSed': 'L\'intero importo sarà addebitato da %p solo per i biglietti utilizzati. %p può imporre una tassa aggiuntiva, anche per i biglietti non utilizzati.',
  'youCanPrintReservationConfirmation': 'Dopo la prenotazione, è possibile stampare immediatamente la conferma.',
  'paymentWillBeChargedOnSite': 'L\'intero importo dell\'ordine dovrà essere pagato sul posto durante il servizio.',
  'missingFacilityId': 'Configurazione non valida.',
  'cannotLoadFacility': 'Impossibile recuperare la struttura %p',
  'add': 'Aggiungi',
  'choose': 'Mostra',
  'choosing': 'Scegliere',
  'hide': 'Nascondi',
  'expand': 'Espandi',
  'menuDetails': 'Dettagli',
  'menuSummary': 'Pagamento',
  'menuSummaryThanks': 'Grazie per il tuo ordine!',
  'noActivitiesInSales': 'Niente da scegliere. Ci controlli presto!',
  'hours': 'ore',
  'minutes': 'min',
  'goToTheNextStep': 'Continua',
  'sellerOfActivities': '%{name} è il venditore dei servizi.',
  'ifYouNeedHelpAssociatedWithOffer': 'Avete domande relative all\'offerta? Scrivere a %p.',
  'saleSupportedByDroplabs': 'Droplabs - sistema di e-commerce, vendita e distribuzione di biglietti, buoni, pass e servizi.',
  'ifYouNeedTechnicalSupport': 'Hai bisogno di supporto tecnico? Contattaci a %p',
  'activityAdmissionDateLabel': 'Data di ammissione: %{name}',
  'chooseDayOfUseActivities': 'Seleziona la data di ammissione',
  'chooseTimeOfUseActivities': 'Seleziona la data di ammissione',
  'chooseNarrowedTimeOfUseActivities': 'Seleziona il restringimento della data di ammissione',
  'chooseTime': 'Scegli un\'ora',
  'chooseEmailForSendingTickets': 'Qual è il tuo indirizzo e-mail?',
  'email': 'Indirizzo e-mail',
  'name': 'Nome',
  'buyersName': 'Nome dell\'acquirente',
  'surname': 'Cognome',
  'buyersSurname': 'Cognome dell\'acquirente',
  'goToThePayment': 'Effettua il pagamento',
  'orderFreeTickets': 'Ordine',
  'buyWithoutPayment': 'Acquista e paga più tardi',
  'businessTicketsHaveBeenSentIntoEmail': 'Il biglietto è stato inviato all\'indirizzo: %p |||| I biglietti sono stati inviati all\'indirizzo: %p',
  'checkSpamInCaseOfNotReceivingEmail': 'Se non vedi nessuna e-mail da noi per qualche minuto, controlla la tua cartella spam o digita Droplabs nella barra di ricerca della tua casella di posta elettronica.',
  'buyAnotherActivities': 'Ordina altre attività',
  'noFixedDatesForChosenActivities': 'Purtroppo non ci sono date disponibili per i servizi scelti.',
  'forChosen': 'Si applica a:',
  'chooseFixedDateBeforePayment': 'Si prega di selezionare la data di ammissione prima di procedere.',
  'chooseFixedDatetimeBeforePayment': 'Si prega di selezionare l\'orario di ammissione per la data scelta prima di procedere.',
  'noFixedDatesForChosenPeriod': 'Non ci sono date di ammissione disponibili per il periodo scelto.',
  'noFixedDatesOnDay': '%{date} non è disponibile.',
  'noFixedDatesBetweenDates': 'Nessuna data disponibile tra %{since} e %{until}.',
  'noFixedDatesBeforeDate': 'Nessuna data disponibile prima di %{date}.',
  'noFixedDatesAfterDate': 'Nessuna data disponibile dopo %{date}.',
  'next': 'Prossimo',
  'back': 'Indietro',
  'onWebsite': 'prenotazione URL',
  'january': 'Gennaio',
  'february': 'Febbraio',
  'march': 'Marzo',
  'april': 'Aprile',
  'may': 'Maggio',
  'june': 'Giugno',
  'july': 'Luglio',
  'august': 'Agosto',
  'september': 'Settembre',
  'october': 'Ottobre',
  'november': 'Novembre',
  'december': 'Dicembre',
  'sundayShort': 'Sole',
  'sunday': 'Domenica',
  'mondayShort': 'Lun',
  'monday': 'Lunedì',
  'mondayTwoPart': 'Lu',
  'tuesdayTwoPart': 'Ma',
  'wednesdayTwoPart': 'Me',
  'thursdayTwoPart': 'Gi',
  'fridayTwoPart': 'Ve',
  'saturdayTwoPart': 'Sa',
  'sundayTwoPart': 'Do',
  'tuesdayShort': 'Mar',
  'tuesday': 'Martedì',
  'wednesdayShort': 'Mer',
  'wednesday': 'Mercoledì',
  'thursdayShort': 'Gio',
  'thursday': 'Giovedì',
  'fridayShort': 'Ven',
  'friday': 'Venerdì',
  'saturdayShort': 'Sab',
  'saturday': 'Sabato',
  'canNotBuyMoreTickets': 'Non è possibile acquistare più di questa attività. Nessun posto a sedere',
  'willEndSoon': 'Scade presto!',
  'itemsLeft': '%{limit} rimasto!',
  'willEndBefore': 'Offerta fino a %{date}!',
  'soldOut': 'Tutto esaurito!',
  'bookingRequired': 'Prenotazione obbligatoria dopo il pagamento',
  'bookingAfterBuy': 'Dopo l\'acquisto riceverai %p per la prenotazione',
  'phone': 'un numero di telefono',
  'website': 'un indirizzo web',
  'bookingEmail': 'un indirizzo e-mail',
  'more': 'più',
  'less': 'meno',
  'from': 'Da',
  'durationUnlimitedSentence': 'Giorno intero',
  'durationUnlimited': 'giorno intero',
  'useNumber': 'Numero di ammissioni',
  'useDatetime': 'Ammissione',
  'betweenDates': 'tra %{since} e %{until}',
  'onDate': 'il %{date}',
  'validBeforeDate': 'prima della %{date}',
  'validInDays': 'in %{number}',
  'today': 'oggi',
  'numberOfDays': 'giorno |||| giorni',
  'activeInDays': 'Valido: %{number} di giorni dopo l\'attivazione',
  'activationInDays': 'Attivazione nella struttura %{days} dopo l\'acquisto',
  'activationDates': 'Attivazione nella struttura %{data}',
  'pickFixedDateInTheNextStep': 'Controllare tutte le date disponibili nella fase successiva',
  'dateOfUse': 'Data di ammissione',
  'withDiscountLabel': 'Hai un codice sconto?',
  'optional': 'opzionale',
  'discountCode': 'Codice sconto',
  'useDiscountCode': 'Usa il codice',
  'discountUsed': 'Codice sconto attivo (%{code}):',
  'invalidDiscountCode': 'Il codice di sconto (%{code}) non funziona per i servizi scelti.',
  'discountCodeNotFound': 'Il codice di sconto (%{code}) non è valido o non è aggiornato.',
  'discountAdditionsNotIncluded': 'Le aggiunte non sono ammissibili per lo sconto.',
  'notEligibleDiscountTickets': 'Gli altri biglietti non sono idonei per questo codice',
  'close': 'chiudere',
  'tryAgain': 'prova di nuovo',
  'phoneNumber': 'Numero di telefono',
  'contactPhoneNumber': 'Numero di telefono del contatto',
  'clientPhoneNumber': 'Numero di telefono dell\'acquirente',
  'restCountries': 'Paesi di riposo',
  'sellerIs': 'Il venditore e',
  'administratorOfThePersonalData': 'L\'amministratore dei suoi dati personali è',
  'sellerCopy':
    `I vostri dati saranno trattati in particolare ai fini dell'esecuzione del contratto, cioè per permettervi di acquistare un biglietto, sulla base dell'articolo 6, paragrafo 1, lettera b) del GDPR.
    Avete il diritto di accesso al contenuto dei vostri dati e il diritto di rettifica, cancellazione, limitazione del trattamento, il diritto alla portabilità dei dati, il diritto di opposizione e di presentare un reclamo all'autorità di controllo.
    I suoi dati non sono soggetti a processi decisionali automatizzati, compresa la profilazione.`,
  'infoAboutPrivacyPolicy': 'Il contenuto completo della clausola informativa è disponibile qui',
  'businessSellerCopy': 'Il venditore è',
  'privacyPolicy': 'Informativa sulla privacy',
  'privacyPolicyAccept': 'Informativa sulla privacy',
  'termsOfServiceAgreement': 'Sono d\'accordo con',
  'termsOfService': 'Condizioni di servizio',
  'inTermsOfService': 'Condizioni di servizio',
  'inPrivacyPolicy': 'Informativa sulla privacy',
  'and': 'e',
  'acceptedTermsOfServiceIn': 'Condizioni accettate che puoi trovare in',
  'facilityTermsOfService': 'Termini di servizio di %{facilityName}',
  'activitiesLimitExceeded': 'Abbiamo esaurito i servizi scelti. Si prega di acquistare altri servizi.',
  'invalidDataOrCharacters': 'I dati forniti non sono validi o contengono caratteri non validi. Cambiali e riprova.',
  'invalidAdmissionDate': 'La data non è valida.',
  'internalErrorMessage': 'Errore, si prega di riprovare più tardi',
  'widgetDisabled': 'Vendita disabilitata. Riprovare più tardi.',
  'bubbleDisabled': 'Saldi esclusi!',
  'buyOnline': 'Acquista online!',
  'buyVoucher': 'Acquista il voucher',
  'buyTicket': 'Acquista il biglietto!',
  'bookingOnline': 'Prenota online',
  'buySkipass': 'Acquista lo skipass',
  'buyCourse': 'Acquista il corso',
  'iWantInvoice': 'Voglio ricevere la fattura',
  'address': 'Via e numero civico / dell\'appartamento',
  'city': 'Città',
  'postalCode': 'Codice postale',
  'companyName': 'Nome dell\'azienda',
  'requiredOnlyForInvoices': 'Richiesto solo per le fatture aziendali',
  'companyNIP': 'ID fiscale',
  'optionalSentence': 'Opzionale',
  'invalidPrice': 'Impossibile procedere al pagamento perché il prezzo visibile è diverso da quello reale - si prega di aggiornare la pagina per caricare i nuovi dati',
  'paymentDelayedInfo': 'La quota di pagamento per l\'ordine è richiesta in base alla fattura IVA che sarà fornita da %{nomeAzienda}',
  'oncePerDay': 'Entrata una volta al giorno',
  'withoutLimit': 'Entrata senza limite giornaliero',
  'ticketsReady': 'Il biglietto è pronto! |||| I biglietti sono pronti!',
  'pleaseWait': 'Si prega di attendere...',
  'downloadTickets': 'Scarica il biglietto |||| Scarica i biglietti',
  'download': 'Scarica',
  'loadingTickets': 'Stiamo preparando i tuoi biglietti!',
  'doOrder': 'Ordina',
  'doReservation': 'Prenota',
  'generateExampleOrder': 'Genera un esempio di ordine',
  'hotelNight': 'notte in albergo',
  'chooseHotelNight': 'Scegli il periodo di alloggio',
  'insertHotelNight': 'Aggiungi il periodo di alloggio',
  'chooseHotelNightDescription': 'Le registrazioni per gli ospiti sono solo durante l\'alloggio.',
  'setNarrowedDates': 'Devi scegliere le date di inizio e fine dell\'alloggio',
  'endDateCantBeTheSameAsStart': 'La fine dell\'alloggio deve essere almeno un giorno dopo l\'inizio dell\'alloggio',
  'save': 'Salva',
  'insertYear': 'Inserire l\'anno',
  'hour': 'ora',
  'realiseInDay': 'Realizzazione il %{date}',
  'realiseInDateWithTime': 'Realizzazione il %{date}, scegli l\'ora nel prossimo passo',
  'missingPaymentProvider': 'Il pagamento non è stato ancora attivato dal venditore. Riprova più tardi.',
  'seeOtherActivities': 'Vedere un\'altra attività',
  'hideOtherActivities': 'Nascondi altre attività',
  'givenDateIsSoldOut': 'I posti per %{date} sono esauriti',
  'givenDateIsSoldOutTestB': 'I posti per il %{date} sono esauriti',
  'isOutOfLimit': 'Nessun posto a sedere per %{time}',
  'setDifferentHour': 'Il numero di posti disponibili per il %{time} è: %{numberOfSeats}, si prega di selezionare un altro orario o data',
  'givenTimeIsSoldOut': 'I posti per %{time} sono esauriti',
  'givenTimeIsSoldOutTestB': 'I posti per le %{time} sono esauriti',
  'hasNoSeatsForGivenDate': 'Nessun posto a sedere per %{date}',
  'setDifferentDate': 'Il numero di posti disponibili per il %{date} è: %{availableSeats}, si prega di selezionare un altro orario o data',
  'seatsSoldOut': 'esauriti',
  'hasNoSeats': 'nessun posto a sedere',
  'chooseDifferentDate': 'Scegli un\'altra data',
  'actuallySoldOut': 'Attualmente esaurito!',
  'noDates': 'Attualmente nessuna data!',
  'customerData': 'Dati del cliente',
  'optionalText': 'facoltativo',
  'numberOfParticipantsLabel': 'Numero di partecipanti: %{name}',
  'avaliableNumber': 'Posti rimanenti: %{number}',
  'filter': 'Filtro',
  'clear': 'Pulito',
  'checkItOut': 'Guarda qui',
  'remove': 'Cancellare',
  'thisMayInterestYou': 'Questo potrebbe essere di vostro interesse',
  'requiredActivityVariantsNotPresentInOrder': 'Per l\'acquisto è necessaria una variante diversa. Tornare all\'elenco dei servizi e selezionare la variante appropriata.',
  'requiredActivityVariants': 'Manca qualcosa nell\'ordine.',
  'backTo': 'Ritorno alla scheda',
  'addSmall': 'aggiungere',
  'requiredActivity': 'il servizio richiesto',
  'oneOfRequiredActivity': 'almeno un servizio richiesto',
  'oneOfRequiredActivityVariants': 'almeno una delle opzioni di servizio richieste',
  'to': 'Fino',
  'marketingConsentsAgreement': 'Voglio iscrivermi alla newsletter (facoltativo)',
  'marketingConsentsPromo': 'Rimani aggiornato su tutte le promozioni e le informazioni, inviamo solo le cose più importanti, promettiamo zero spam!',
  'setAllConsents': 'Contrassegnare tutti',
  'InvalidNipFormat': 'Partita IVA non valida',
  'numberOfPeopleInGroup': 'Numero di persone nel gruppo',
  'paymentFormOutside': 'Forma di pagamento al di fuori di Droplabs',
  'other': 'Altro',
  'paymentTerminal': 'Terminale di pagamento',
  'cash': 'Contanti',
  'bankTransfer': 'Trasferimento al conto',
  'prefix': 'Prefisso',
  'paymentCompleteUntilBeforeMinimumDateTime': 'Il periodo di pagamento è troppo breve',
  'paymentCompleteUntilDaysLimitExceeded': 'Il periodo di pagamento è troppo lungo',
  'setPaymentDate': 'Stabilire una scadenza di pagamento',
  'setPaymentDateDesc': 'Se il cliente non paga l\'ordine entro la data stabilita, i posti torneranno al pool di posti disponibili.',
  'newOrder': 'Creare un nuovo ordine',
  'LastStep': 'Avete un ultimo passo da compiere...',
  'copyLinkAndSendToCustomer': 'Copiare il link e inviarlo al cliente per pagare l\'ordine.',
  'orClick': 'oppure fare clic su',
  'CopyLink': 'Copia il link',
  'paymentOutside': 'Pagamento al di fuori di Droplabs',
  'paymentOnline': 'Pagamento online',
  'cancel': 'Annulla',
  'setDateAndTime': 'Selezionare data e ora',
  'privatePerson': 'Persona privata',
  'company': 'Azienda',
  'invoiceOptionalNote': 'Nota di fattura (opzionale)',
  'youWillReceiveInvoiceSimplified': 'Riceverai una ricevuta con il tuo codice fiscale (NIP), che rappresenta una fattura semplificata.',
  'nameOptional': 'Nome (facoltativo)',
  'surnameOptional': 'Cognome (facoltativo)',
  'emailOptional': 'Indirizzo e-mail (facoltativo)',
  'isExpired': 'è scaduto',
  'isAdmissionDateInvalid': 'riguarda un tempo di consegna diverso',
  'isInvalid': 'non è valido',
  'isUsed': 'è stato utilizzato',
  'buyerData': 'Dati del compratore',
  'requiredEmailAlert': 'Ricordati di fornire l\'indirizzo email del cliente, altrimenti non riceverà la fattura.',
  'issueAnInvoice': 'Emetti una fattura',
  'areYouLeaving': 'State già uscendo?',
  'exitConfirmationComment': 'Acquistando ora, vi garantite un prezzo invariato. Se si abbandona l\'acquisto, si perderanno i passi compiuti fino a quel momento.',
  'abandonPurchase': 'Abbandona l\'acquisto',
  'continueShopping': 'Continuare l\'acquisto',
  'thisFeatureIsNotEnabled': 'Questa funzione non è disponibile',
  'thisFeatureIsNotEnabledSolution': 'Non c\'è niente da perdere! Scriveteci a %p, vi aiuteremo a scegliere il piano giusto.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Si prega di attendere',
  'waitingForPaymentConfirmation': 'Stiamo attendendo la conferma del pagamento.',
  'paymentProcessInterrupted': 'Se il processo di pagamento è stato interrotto, puoi pagare l\'ordine',
  'clickHere': 'cliccando qui',
  'ticketsLoadedAndSentToEmail': 'I biglietti saranno caricati qui sotto e inviati al tuo indirizzo email:',
  'paymentAccepted': 'Pagamento accettato',
  'orderNumber': 'Numero d\'ordine:',
  'orderSendOnEmail': 'Conferma dell\'ordine inviata via e-mail:',
  'emailNotGetThrough': 'Email non ricevuta?',
  'emailNotGetThroughTooltip': 'Se non visualizzi l\'email entro pochi minuti dal pagamento, controlla la cartella dello spam. Se ciò non aiuta, digita \'Droplabs\' nella ricerca del tuo programma di posta elettronica.',
  'orderCreated': 'Ordine creato',
  'generatingTickets': 'Generazione biglietti',
  'generatingTicketsDescription': 'A breve potrai scaricare il tuo biglietto e aggiungerlo al tuo portafoglio virtuale. Abbiamo anche inviato una copia dello stesso biglietto all\'indirizzo email fornito.',
  'everythingDone': 'Tutto è pronto!',
  'ticketsReadyToDownload': 'I biglietti sono pronti per il download. Clicca il pulsante per scaricare.',
  'showTickets': 'Mostra biglietti',
  'hideTickets': 'Nascondi biglietti',
  'pdfDocumentIsReady': 'Successo, PDF pronto!',
  'clickForPdfDocument': 'PDF pronto per il download. Clicca il pulsante per scaricare.',
  'pdfDocumentIsGenerating': 'Generazione PDF',
  'pdfDocumentIsGeneratingDescription': 'La generazione del PDF potrebbe richiedere un momento, non è necessario attendere.',
  'generating': 'Generazione...',
  'sendOrder': 'Invia Ordine',
  'sendOrderDescription': 'Fornisci l\'indirizzo email a cui l\'ordine, insieme al biglietto, dovrebbe essere inviato.',
  'orderSendedOnEmail': 'L\'ordine è stato inviato all\'indirizzo email fornito',
  'orderSomethingElse': 'Vuoi ordinare qualcos\'altro?',
  'actionDisabledInPreviewMode': 'Questa azione non può essere eseguita, questa vista è solo per anteprima.',
  'anotherRecipient': 'Voglio specificare un altro destinatario',
  'recipientName': 'Nome del destinatario',
  'digitalShippingMethod': 'Invio elettronico a un indirizzo e-mail designato',
  'somethingIsWrong': 'Qualcosa è andato storto...',
  'waitingForPaymentError': 'L\'ordine è stato annullato o il tempo di pagamento è scaduto. Generare un nuovo ordine o rinnovare il pagamento..',
  'waitingForPayment': 'In attesa del pagamento',
  'waitingForPaymentDesc': 'L\'importo dell\'ordine è stato inviato al terminale Viva Wallet. Accettate il pagamento non appena l\'importo appare sullo schermo del terminale. Se non si desidera accettare il pagamento, annullare la transazione.',
  'sendAgain': 'Reinvio',
  'paymentRepeatError': 'Non è stato possibile inviare nuovamente il pagamento',
  'paymentRepeatSucces': 'Il pagamento è stato reinviato',
  'rePayment': 'Ripeti pagamento',
  'setTicketAsUsed': 'Segna come Usato',
  'setTicketAsUsedDescription': 'Registra l\'uso di tutti i biglietti appena generati, validi per oggi.',
  'useTickets': 'Usa i biglietti',
  'useTicketsSuccess': 'I biglietti sono stati segnati come usati con successo.',
  'useTicketsError': 'Impossibile segnare i biglietti come usati.',
  'mismatchedTicketDate': 'L\'ordine si riferisce a una diversa data di erogazione.',
  'ticketInactive': 'L\'ordine non è attivo.',
  'useTicketsErrorMoreDetails': 'È possibile trovare i dettagli dell\'ordine nella sezione \'Supporto clienti\' o \'Controllo accessi\'.',
  'country': 'Paese',
  'notSavedData': 'I dati non verranno salvati',
  'notSavedDataDesc': 'Se sono stati inseriti dati aggiuntivi, si noti che si perderanno queste informazioni quando si andrà a selezionare i servizi.',
  'changeActivities': 'Modificare gli oggetti',
  'AD': 'Andorra',
  'AE': 'Emirati Arabi Uniti',
  'AF': 'Afghanistan',
  'AG': 'Antigua e Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albania',
  'AM': 'Armenia',
  'AO': 'Angola',
  'AQ': 'Antartide',
  'AR': 'Argentina',
  'AS': 'Samoa Americane',
  'AT': 'Austria',
  'AU': 'Australia',
  'AW': 'Aruba',
  'AX': 'Isole Åland',
  'AZ': 'Azerbaigian',
  'BA': 'Bosnia ed Erzegovina',
  'BB': 'Barbados',
  'BD': 'Bangladesh',
  'BE': 'Belgio',
  'BF': 'Burkina Faso',
  'BG': 'Bulgaria',
  'BH': 'Bahrein',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Saint-Barthélemy',
  'BM': 'Bermuda',
  'BN': 'Brunei Darussalam',
  'BO': 'Bolivia (Stato plurinazionale di)',
  'BQ': 'Bonaire, Sint Eustatius e Saba',
  'BR': 'Brasile',
  'BS': 'Bahamas',
  'BT': 'Bhutan',
  'BV': 'Isola Bouvet',
  'BW': 'Botswana',
  'BY': 'Bielorussia',
  'BZ': 'Belize',
  'CA': 'Canada',
  'CC': 'Isole Cocos (Keeling)',
  'CD': 'Congo, Repubblica Democratica del',
  'CF': 'Repubblica Centrafricana',
  'CG': 'Congo',
  'CH': 'Svizzera',
  'CI': 'Costa d\'Avorio',
  'CK': 'Isole Cook',
  'CL': 'Cile',
  'CM': 'Camerun',
  'CN': 'Cina',
  'CO': 'Colombia',
  'CR': 'Costa Rica',
  'CU': 'Cuba',
  'CV': 'Capo Verde',
  'CW': 'Curaçao',
  'CX': 'Isola di Natale',
  'CY': 'Cipro',
  'CZ': 'Cechia',
  'DE': 'Germania',
  'DJ': 'Gibuti',
  'DK': 'Danimarca',
  'DM': 'Dominica',
  'DO': 'Repubblica Dominicana',
  'DZ': 'Algeria',
  'EC': 'Ecuador',
  'EE': 'Estonia',
  'EG': 'Egitto',
  'EH': 'Sahara Occidentale',
  'ER': 'Eritrea',
  'ES': 'Spagna',
  'ET': 'Etiopia',
  'FI': 'Finlandia',
  'FJ': 'Figi',
  'FK': 'Isole Falkland (Malvine)',
  'FM': 'Micronesia, Stati Federati di',
  'FO': 'Isole Fær Øer',
  'FR': 'Francia',
  'GA': 'Gabon',
  'GB': 'Regno Unito di Gran Bretagna e Irlanda del Nord',
  'GD': 'Grenada',
  'GE': 'Georgia',
  'GF': 'Guyana Francese',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibilterra',
  'GL': 'Groenlandia',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadalupa',
  'GQ': 'Guinea Equatoriale',
  'GR': 'Grecia',
  'GS': 'Georgia del Sud e isole Sandwich meridionali',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HK': 'Hong Kong',
  'HM': 'Isole Heard e McDonald',
  'HN': 'Honduras',
  'HR': 'Croazia',
  'HT': 'Haiti',
  'HU': 'Ungheria',
  'ID': 'Indonesia',
  'IE': 'Irlanda',
  'IL': 'Israele',
  'IM': 'Isola di Man',
  'IN': 'India',
  'IO': 'Territorio britannico dell’Oceano Indiano',
  'IQ': 'Iraq',
  'IR': 'Iran (Repubblica Islamica di)',
  'IS': 'Islanda',
  'IT': 'Italia',
  'JE': 'Jersey',
  'JM': 'Giamaica',
  'JO': 'Giordania',
  'JP': 'Giappone',
  'KE': 'Kenya',
  'KG': 'Kirghizistan',
  'KH': 'Cambogia',
  'KI': 'Kiribati',
  'KM': 'Comore',
  'KN': 'Saint Kitts e Nevis',
  'KP': 'Corea del Nord',
  'KR': 'Corea del Sud',
  'KW': 'Kuwait',
  'KY': 'Isole Cayman',
  'KZ': 'Kazakistan',
  'LA': 'Lao, Repubblica Democratica Popolare',
  'LB': 'Libano',
  'LC': 'Santa Lucia',
  'LI': 'Liechtenstein',
  'LK': 'Sri Lanka',
  'LR': 'Liberia',
  'LS': 'Lesotho',
  'LT': 'Lituania',
  'LU': 'Lussemburgo',
  'LV': 'Lettonia',
  'LY': 'Libia',
  'MA': 'Marocco',
  'MC': 'Monaco',
  'MD': 'Moldova, Repubblica di',
  'ME': 'Montenegro',
  'MF': 'Saint Martin (parte francese)',
  'MG': 'Madagascar',
  'MH': 'Isole Marshall',
  'MK': 'Macedonia del Nord',
  'ML': 'Mali',
  'MM': 'Myanmar',
  'MN': 'Mongolia',
  'MO': 'Macao',
  'MP': 'Isole Marianne Settentrionali',
  'MQ': 'Martinica',
  'MR': 'Mauritania',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Mauritius',
  'MV': 'Maldive',
  'MW': 'Malawi',
  'MX': 'Messico',
  'MY': 'Malaysia',
  'MZ': 'Mozambico',
  'NA': 'Namibia',
  'NC': 'Nuova Caledonia',
  'NE': 'Niger',
  'NF': 'Isola Norfolk',
  'NG': 'Nigeria',
  'NI': 'Nicaragua',
  'NL': 'Paesi Bassi, Regno dei',
  'NO': 'Norvegia',
  'NP': 'Nepal',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'Nuova Zelanda',
  'OM': 'Oman',
  'PA': 'Panama',
  'PE': 'Perù',
  'PF': 'Polinesia Francese',
  'PG': 'Papua Nuova Guinea',
  'PH': 'Filippine',
  'PK': 'Pakistan',
  'PL': 'Polonia',
  'PM': 'Saint Pierre e Miquelon',
  'PN': 'Pitcairn',
  'PR': 'Porto Rico',
  'PS': 'Palestina, Stato di',
  'PT': 'Portogallo',
  'PW': 'Palau',
  'PY': 'Paraguay',
  'QA': 'Qatar',
  'RE': 'Réunion',
  'RO': 'Romania',
  'RS': 'Serbia',
  'RU': 'Federazione Russa',
  'RW': 'Ruanda',
  'SA': 'Arabia Saudita',
  'SB': 'Isole Salomone',
  'SC': 'Seychelles',
  'SD': 'Sudan',
  'SE': 'Svezia',
  'SG': 'Singapore',
  'SH': 'Sant’Elena, Ascensione e Tristan da Cunha',
  'SI': 'Slovenia',
  'SJ': 'Svalbard e Jan Mayen',
  'SK': 'Slovacchia',
  'SL': 'Sierra Leone',
  'SM': 'San Marino',
  'SN': 'Senegal',
  'SO': 'Somalia',
  'SR': 'Suriname',
  'SS': 'Sud Sudan',
  'ST': 'São Tomé e Príncipe',
  'SV': 'El Salvador',
  'SX': 'Sint Maarten (parte olandese)',
  'SY': 'Repubblica Araba Siriana',
  'SZ': 'Eswatini',
  'TC': 'Isole Turks e Caicos',
  'TD': 'Ciad',
  'TF': 'Territori Francesi del Sud',
  'TG': 'Togo',
  'TH': 'Thailandia',
  'TJ': 'Tagikistan',
  'TK': 'Tokelau',
  'TL': 'Timor Est',
  'TM': 'Turkmenistan',
  'TN': 'Tunisia',
  'TO': 'Tonga',
  'TR': 'Turchia',
  'TT': 'Trinidad e Tobago',
  'TV': 'Tuvalu',
  'TW': 'Taiwan, Provincia della Cina',
  'TZ': 'Tanzania, Repubblica Unita di',
  'UA': 'Ucraina',
  'UG': 'Uganda',
  'UM': 'Isole minori esterne degli Stati Uniti',
  'US': 'Stati Uniti d’America',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VA': 'Santa Sede',
  'VC': 'Saint Vincent e Grenadine',
  'VE': 'Venezuela (Repubblica Bolivariana di)',
  'VG': 'Isole Vergini Britanniche',
  'VI': 'Isole Vergini Americane',
  'VN': 'Vietnam',
  'VU': 'Vanuatu',
  'WF': 'Wallis e Futuna',
  'WS': 'Samoa',
  'YE': 'Yemen',
  'YT': 'Mayotte',
  'ZA': 'Sudafrica',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'nearestDate': 'Data disponibile più vicina',
  'nearestDateWithHour': '%{day} alle %{hour}',
  'tomorrow': 'domani',
  'paymentMethod': 'Metodo di pagamento',
  'secureFormDescription': 'Paga rapidamente con bonifico / carta di credito',
  'typeGeneratedBlikCode': 'Inserisci il codice monouso generato nella tua app bancaria',
  'blik': 'BLIK',
  'typeBlikCode': 'Inserisci il codice BLIK',
  'blikCodePatternError': 'Il codice deve contenere 6 cifre',
  'blikCode': 'Codice BLIK',
  'simplifiedInvoice': 'Semplificato',
  'fullInvoice': 'Completo',
  'privateInvoice': 'Denominata',
  'simplifiedInvoiceInformation': 'Il cliente riceverà una ricevuta con il numero di partita IVA come fattura semplificata. Può essere emessa solo per le aziende registrate in Polonia. Per le aziende straniere, scegliere la fattura completa.',
  'isNotPolishNip': 'Il numero NIP indicato non è polacco.',
  'polishNip': 'Numero di partita IVA polacco',
  'checkTheBankingApp': 'Controlla l\'app bancaria',
  'confirmPaymentWithPin': 'Ora conferma il pagamento con il tuo PIN utilizzando l\'app sul tuo telefono.',
  'ticketsLoadedAfterConfirm': 'I biglietti verranno caricati dopo la conferma del pagamento e inviati per email:',
  'paymentWithBlik': 'Pagare con BLIK',
  'cannontLoadPage': 'Impossibile caricare questa pagina',
  'refreshPage': 'Ricarica la pagina',
  'isSoldOut': 'Esaurito',
  'removeSelectedActivities': 'Eliminare i servizi selezionati',
  'removeSelectedServicesQuestion': 'Rimuovere i servizi selezionati?',
  'allServicesWillBeRemoved': 'Tutti i servizi verranno rimossi e tornerai alla ri-selezione',
  'hourFull': 'Ora',
  'hasNoSeatsTest': 'Nessun posto',
  'pleaseSelectDate': 'Selezionare una data',
  'pleaseSelectTime': 'Selezionare un orario',
  'selected': 'Selezionato'
};

/* eslint-enable max-len */
