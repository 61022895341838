import uuidV4 from 'uuid/v4';
import {WidgetConfig, WidgetMode} from '../types';

/**
 * We expect a script with configuration OR global object with a number of configurations.
 * FAQ:
 * 1. Q: Should config work for existing implementations as well?
 *    A: YES.
 * 2. Q: Does config existence indicate that this is "modal" mode? Existing
 *    implementations do not have (and possibly will never have) configs.
 *    A: NO, configs exist in default form also for Inline Widgets (however not useful).
 */

declare global {
  interface Window {
    dlConfig?: WidgetConfig[];
    dlDebug?: boolean;
    dlWidgetInitialized: boolean;
  }
}

/**
 * Default mode is always set to inline, to be backwards compatible with existing implementations
 */
const DEFAULT_MODE = WidgetMode.inline;
/**
 * This is a selector that is used by default to toggle modal open
 */
const DEFAULT_SELECTOR = '.js-open-droplabs-widget';

/**
 * Utility to validate mode
 * @param {string} mode - optional mode string
 * @returns {WidgetMode} WidgetMode
 */
const getValidMode = (mode?: string): WidgetMode =>
  (mode && mode in WidgetMode ? (mode as WidgetMode) : DEFAULT_MODE);

/**
 * Utility to check if debug mode is enabled
 * @returns {boolean} - debug mode enabled
 */
const isDebugMode = (): boolean => window.dlDebug === true;

/**
 * Utility to log debug messages
 * @param {any} args - optional arguments
 * @returns {void}
 */
const debugLog = (...args: any): void => {
  if (isDebugMode()) {
    console.warn(...args);
  }
};

/**
 * Utility to retrieve selector to toggle widget modal
 * @param {DOMStringMap} dataset - config element DataSet
 * @returns {string} - CSS Selector
 */
const getSelector = (dataset?: DOMStringMap): string =>
  dataset?.selector ?? DEFAULT_SELECTOR;

const getConfigFromGlobalObject = (externalConfigElement: HTMLElement, dlConfig?: WidgetConfig[]): WidgetConfig[] => {
  if (!dlConfig || !Array.isArray(dlConfig)) {
    return [];
  }

  return dlConfig.map(config => ({
    ...config,
    element: externalConfigElement,
    mode: getValidMode(config.mode),
    // generate a random ID if not present, important for modal, ignored for inline
    // Notatka do siebie samego: dlWidgetScriptId nie może być użyty do storage, bo jest generowany losowo
    dlWidgetScriptId: config.dlWidgetScriptId ?? uuidV4()
  }));
};

/**
 * Retrieve widget configuration from the DOM / Global Object
 * @param {HTMLElement} externalConfigElement - optional config element
 * @returns {WidgetConfig} - widget configuration
 */
const getConfig = (externalConfigElement: HTMLElement): WidgetConfig[] => {
  const config = getConfigFromGlobalObject(externalConfigElement, window.dlConfig);

  if (!config.length) {
    config.push(getConfigFromDataAttributes(externalConfigElement));
    debugLog('[DL - CONFIGURATION FROM DATA ATTRIBUTES]', config);
  } else {
    debugLog('[DL - CONFIGURATION FROM GLOBAL OBJECT]', config);

    if (isDebugMode()) {
      const ignoredConfig = getConfigFromDataAttributes(externalConfigElement);

      if (ignoredConfig) {
        debugLog('[DL - CONFIGURATION FROM DATA ATTRIBUTES PRESENT, IGNORING]', ignoredConfig);
      }
    }
  }

  return config;
};

export default getConfig;

// Get and validate configuration from data attributes
const getConfigFromDataAttributes = (element: HTMLElement): WidgetConfig => {
  const dataset = element.dataset ?? {};
  const mode = getValidMode(dataset?.mode);
  let dlWidgetScriptId = dataset?.dlWidgetScriptId;

  if (mode === WidgetMode.modal && !dataset.dlWidgetScriptId) {
    debugLog('[DL] dlWidgetScriptId is missing on config element for modal mode');

    dlWidgetScriptId = uuidV4();
  }

  const config: WidgetConfig = {
    ...dataset,
    element,
    mode,
    selector: getSelector(dataset),
    dlWidgetScriptId
  };

  return config;
};
