import {
  getAdditionById,
  isActivityHotelNarrowed,
  isActivityWithDates
} from './activity/activity';
import {getVariantById, isVariantMultiday} from './activity/variant';
import {getBasketItemPrice} from './basketItemPrice';
import {createCurrentDate, getStartOf} from './dates/timezoneDates';
import {getFormattedPrice} from './format';
import locale from './locale';

/**
 * Returns initialized basket items
 * @param {Activity} activity - activity object
 * @param {Variant} variant - variant object
 * @param {BasketItemSelections} oldSelections - old basket item selections
 * @return {BasketItemSelections} basket item selections
 */
export const createBasketItemSelections = (activity, variant, oldSelections) => {
  const {passType, admissionPolicy} = activity;
  const {
    startDatetime,
    minNumberOfParticipants
  } = admissionPolicy;
  const numberOfDays = variant?.numberOfAdmissionDatesMin || null;
  const validSince = isActivityHotelNarrowed(passType) ?
    startDatetime || getStartOf(createCurrentDate(), 'day') : null;
  const numberOfSeats = oldSelections?.numberOfSeats ?? (variant?.minNumberOfSeats || null);
  const numberOfParticipants = oldSelections?.numberOfParticipants ?? (minNumberOfParticipants || null);

  return {
    date: null,
    time: null,
    dates: null,
    validSince,
    validUntil: null,
    numberOfParticipants,
    numberOfSeats,
    numberOfDays,
    numberOfHotelNights: 1,
    answers: [],
    additions: [],
    discount: null
  };
};
/**
 * Returns basket item additions
 * @param {BasketItem} basketItem - basket item object
 * @param {Activity} activity - activity object
 * @return {Additions[] | []} activity additions for basket item variant
 */
export const getBasketItemAdditions = (basketItem, activity) => {
  const {additions} = {...activity};
  const {variantId: basketItemVariantId} = basketItem;

  if (!additions?.length) {
    return [];
  }

  return additions.filter(({variantIds}) => variantIds.find(variantId => variantId === basketItemVariantId));
};

export const getVariantAdditions = (additions, variantId) =>
  additions.filter(({variantIds}) => variantIds.find(additionVariantId => additionVariantId === variantId));

/**
 * Returns basket item questions
 * @param {BasketItem} basketItem - basket item object
 * @param {Activity} activity - activity object
 * @return {Questions[] | []} activity questions for basket item variant
 */
export const getBasketItemQuestions = (basketItem, activity) => {
  const {questions} = {...activity};
  const {variantId: basketItemVariantId} = basketItem;

  if (!questions?.length) {
    return [];
  }

  return questions.filter(({variantIds}) => variantIds.find(variantId => variantId === basketItemVariantId));
};

export const getVariantQuestions = (questions, variantId) =>
  questions.filter(({variantIds}) => variantIds.find(questionVariantId => questionVariantId === variantId));

/**
 * Returns initialized basket items
 * @param {Activities[]} activities - group activities
 * @param {Activities[]} upsellActivities - group of upsell activities
 * @param {Activities[]} pickedUpsellActivities - group of picked upsell activities
 * @param {BasketItems[]} basketItems - array of basket items
 * @return {BasketItems[]} basketItems - extanded by additions
 */

export const prepareBasket = (activities, upsellActivities, pickedUpsellActivities, basketItems) => {
  const preparedBasket = basketItems.map(basketItem => {
    const activity = activities.find(activity => activity.id === basketItem.activity.id);
    const upsellActivity = upsellActivities?.find(upsellActivity => upsellActivity.id === basketItem.activity.id);
    const pickedUpsellActivity = pickedUpsellActivities?.find(
      pickedUpsellActivity => pickedUpsellActivity.id === basketItem.activity.id
    );
    const variant = basketItem.variant;
    const properActivity = activity || upsellActivity || pickedUpsellActivity;
    const additions = getBasketItemAdditions(basketItem, properActivity);
    const questions = getBasketItemQuestions(basketItem, properActivity);

    return basketItem = {
      ...basketItem,
      variant: {
        ...variant,
        additions,
        questions
      },
      activity: properActivity
    };
  });

  return preparedBasket;
};

/**
 * Returns initialized basket items
 * @param {Activity} activity - activity object
 * @param {number} variantId - variantId
 * @param {string} uid - unique id for basket item
 * @param {boolean} isUpsell - is item from upsell flag
 * @return {BasketItem} crated basket item
 */
export const createBasketItem = (activity, variantId, uid, isUpsell = false) => {
  const {id: activityId, variants, additions, questions} = {...activity};
  const variant = getVariantById(variants, variantId);
  const defaultUid =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  return {
    uid: uid || defaultUid,
    activityId,
    variantId,
    activity,
    variant: {
      ...variant,
      ...additions && {additions: getVariantAdditions(additions, variantId)},
      ...questions && {questions: getVariantQuestions(questions, variantId)}
    },
    selections: createBasketItemSelections(activity, variant),
    isUpsell
  };
};

/**
 * Returns activity basket items
 * @param {BasketItem[]} basketItems - array of basket items
 * @param {number} activityId - activity id
 * @return {BasketItem[]} activity basket items
 */
export const getActivityBasketItems = (basketItems, activityId) =>
  basketItems.filter(item => item.activityId === activityId);

/**
 * Returns variant basket items
 * @param {BasketItem[]} basketItems - array of basket items
 * @param {number} activityId - activity id
 * @param {number} variantId - variant id
 * @return {BasketItem[]} variant basket items
 */
export const getVariantBasketItems = (basketItems, activityId, variantId) =>
  basketItems.filter(item => item.activityId === activityId && item.variantId === variantId);

/**
 * Returns variant basket item
 * @param {BasketItem[]} basketItems - array of basket items
 * @param {number} activityId - activity id
 * @param {number} variantId - variant id
 * @return {BasketItem} variant basket item
 */
export const getVariantBasketItem = (basketItems, activityId, variantId) =>
  basketItems.find(item => item.activityId === activityId && item.variantId === variantId);

/**
 * Returns valid price if is greather or equal 0, returns null otherwise
 * @param {number} price - price
 * @return {number | null} valid price
 */
export const getValidPrice = price => (price >= 0 ? price : null);

/**
 * Returns status of selected additions
 * @param {BasketItem} basketItem - basket item object
 * @return {boolean} return true if any additions are selected
 */
export const itemHasSelectedAdditions = basketItem => {
  const {selections} = basketItem;
  const {additions} = selections;

  return Boolean(additions?.length);
};

/**
 * Returns basket value including discount and additions
 * @param {BasketItem} basketItem - basket item object
 * @return {number} summarized value of the basket item additions
 */
export const getBasketItemAdditionsPrice = ({activity, selections, variant}) => {
  const {additions: activityAdditions} = activity;
  const {additions, numberOfParticipants, numberOfSeats: selectedNumberOfSeats} = selections;

  if (additions?.length) {
    return additions.reduce((totalCost, {additionId, quantity}) => {
      const addition = getAdditionById(activityAdditions, additionId);
      const {price, isPricePerParticipant} = addition;
      let additionPrice = price.amount * quantity;
      const currency = price.currency;

      if (isPricePerParticipant) {
        additionPrice *= selectedNumberOfSeats || 1;
        additionPrice *= variant.numberOfSeats || 1;
        additionPrice *= numberOfParticipants || 1;
      }

      return {
        price: parseFloat((totalCost.price + additionPrice).toFixed(2)),
        currency
      };
    }, {price: 0});
  }

  return {price: 0};
};

/**
 * Returns basket value including discount and selections
 * @param {BasketItem[]} basketItems - array of basket items
 * @param {boolean} includeDiscount - include discount
 * @return {number} summarized value of the basket items
 */
export const getBasketValue = (basketItems, includeDiscount) =>
  basketItems.reduce((amount, item) => {
    const {price} = getBasketItemPrice(item, includeDiscount);
    const {price: additionsPrice} = getBasketItemAdditionsPrice(item);

    return parseFloat((
      amount +
    getValidPrice(price) +
    getValidPrice(additionsPrice)
    ).toFixed(2));
  }, 0);

/**
 * Returns basket item raw price (without discount and selections)
 * @param {BasketItem} item - basket items object
 * @return {number | null} value of the basket item
 */
export const getBasketItemRawPrice = item => {
  const {activity, variant} = item;
  const {passType} = activity;

  if (variant) {
    if (isActivityWithDates(passType)) {
      return {price: variant.datesCostMin.price || null};
    }

    return {price: variant.cost.price};
  }

  return {price: null};
};

/**
 * Returns basket initial value (without discount and selections)
 * @param {BasketItem[]} basketItems - array of basket items
 * @return {number} summarized initial value of the basket items
 */
export const getBasketRawValue = basketItems =>
  basketItems.reduce((amount, item) => {

    const {price: basketItemRawPrice} = getBasketItemRawPrice(item);
    const {price: basketItemAdditionsPrice} = getBasketItemAdditionsPrice(item);

    return parseFloat((
      amount +
    getValidPrice(basketItemRawPrice) +
    getValidPrice(basketItemAdditionsPrice)
    ).toFixed(2));
  }, 0);

/**
 * Returns an map object, where unique key equals to 'id-variantId' string,
 * and value is an array with corresponding items
 * @param {BasketItem[]} basketItems - array of basket items
 * @return {Object.<string, BasketItem[]>} summarized value of the basket items
 */
export const getBasketIdVariantIdGroup = basketItems => {
  const group = {};

  basketItems.forEach(item => {
    const id = `${item.activityId}-${item.variantId}`;

    if (!group[id]) {
      group[id] = [];
    }

    group[id].push(item);
  });

  return group;
};

/**
 * Returns array of unique, counted basketItems
 * @param {BasketItem[]} basketItems - array of basket items
 * @return {BasketItem[]} array of unique, counted basketItems
 */
export const getUniqueBasketItems = basketItems =>
  basketItems.reduce((grouppedBasketItems, basketItem) => {
    const {activityId, variantId} = basketItem;
    const grouppedItem = getVariantBasketItem(grouppedBasketItems, activityId, variantId);

    if (grouppedItem) {
      const grouppedItemIndex = grouppedBasketItems.indexOf(grouppedItem);

      grouppedBasketItems[grouppedItemIndex].count++;
    } else {
      grouppedBasketItems.push({...basketItem, count: 1});
    }

    return grouppedBasketItems;
  }, []);

/**
 * Returns array of all dates arrays
 * @param {BasketItem[]} basketItems - array of basket items
 * @return {Array.<Array.<AdmissionDateProcessed>>} array of items dates array
 */
export const getBasketItemsAllDates = basketItems =>
  basketItems.reduce((allDates, {activity}) => [...allDates, activity.admissionPolicy.dates], []);

/**
 * Return array of basket item arrays groupped by activity id
 * [[activity1, activity1], [activity2, activity2]]
 * @param {Array} activityItems - array of basket items
 * @param {BasketItem} basketItem - basket items object
 * @return {Array} organized basket by activities
 */
export const getBasketGrouppedByActivities = (activityItems, basketItem) => {
  const itemGroupIndex = activityItems
    .findIndex(items => items?.[0].activityId === basketItem.activityId);

  if (itemGroupIndex >= 0) {
    activityItems[itemGroupIndex].push(basketItem);
  } else {
    activityItems.push([basketItem]);
  }

  return activityItems;
};

export const getBasketItemDiscountedStatus = basketItem => {
  const itemPrice = getBasketItemPrice(basketItem);
  const {price: basketItemPrice} = {...itemPrice};
  const itemWithDiscount = getBasketItemPrice(basketItem, true);
  const {price: basketItemPriceWithDiscount} = {...itemWithDiscount};

  return Boolean(basketItemPrice - basketItemPriceWithDiscount);
};

/**
 * Returns basket item date range if is multiday variant
 * @param {BasketItem} basketItem - basket item object
 * @return {DateRange | null} item date range
 */
export const getApiDates = ({selections, variant}) => {
  const {dates, time} = selections;
  const numberOfDates = dates?.length;

  if (numberOfDates && isVariantMultiday(variant)) {
    const {dates} = selections;
    const lastDayIndex = dates.length - 1;
    const endDate = dates[lastDayIndex];

    const datesRange = {
      start: dates[0].group[0].originalDate,
      end: endDate.group[0].originalDate
    };

    return {
      datesRange
    };
  }

  if (time) {
    return {
      date: time.originalDate
    };
  }

  if (numberOfDates) {
    const singleDate = dates[0]?.group[0]?.originalDate;

    return {
      date: singleDate
    };
  }

  return null;
};

export const getBasketItemName = ({activity, variant}) => {
  const {name: activityName} = activity;
  const {name: variantName} = variant;

  return `${activityName}${variantName ? `, ${variantName}` : ''}`;
};

/**
 * Returns array of objects with discounts data
 * exist under uid key
* @param {BasketItem[]} basketItems - array of basket items
* @return {itemsDiscountsData[]} - array of objects with discounts data
 */

export const getDiscountList = basketItems =>
  basketItems.map(basketItem => {
    const {activity, variant, selections, variantId} = basketItem;
    const activityName = activity.name;
    const variantName = variant.name;
    const variantNameLabel = variantName ? ', ' + variantName : '';
    const label = activityName + variantNameLabel;
    const {price, currency} = getBasketItemPrice(basketItem);
    const priceDiscounted = selections.discount.price;
    const isDiscounted = price !== priceDiscounted;

    return {label, priceDiscounted, price, currency, variantId, isDiscounted};
  });

export const getDiscountTextMessage = (itemsDiscountsData, code, withAdditions) => {
  const messageHeader = locale.translate('discountUsed', {code: code.toUpperCase()});
  const someTicketIsNotDiscounted = itemsDiscountsData.some(({isDiscounted}) => !isDiscounted);

  const discountList = itemsDiscountsData.reduce((message, itemDiscountData) => {
    const label = itemDiscountData.label;
    const priceDiscounted = getFormattedPrice(itemDiscountData.priceDiscounted);
    const price = getFormattedPrice(itemDiscountData.price);
    const currency = itemDiscountData.currency;
    const currencyText = locale.translate(`${currency}`);
    const isDiscounted = itemDiscountData.isDiscounted;

    if (isDiscounted) {
      return message += ' ' + '1 x ' + label + ': ' + priceDiscounted + ' ' + currencyText + ' ' + price + ' ' + currencyText; // eslint-disable-line max-len
    }

    return message;
  }, '');

  const ticketExplanation = someTicketIsNotDiscounted ? ' ' + locale.translate('notEligibleDiscountTickets') : '';
  const additionExplanation = withAdditions ? ' ' + locale.translate('discountAdditionsNotIncluded') : '';

  return messageHeader + discountList + ticketExplanation + additionExplanation;
};

export const sortActivityBasketItemsByVariants = activityBasketItems => {
  const sortByVariantPriority = (prevObject, nextObject) => nextObject.variant.priority - prevObject.variant.priority;

  return activityBasketItems.sort(sortByVariantPriority);
};

export const getActivitiesUniquesIds = basketItems =>
  [...new Set(basketItems.map(item => item.activity.id))];

export const getActivitiesVariantUniquesIds = basketItems =>
  [...new Set(basketItems.map(item => item.variant.id))];

export const getBasketItemsByActivityId = (basketItems, activityId) => {
  const activityBasketItems = basketItems.filter(item => item.activityId === activityId);

  return sortActivityBasketItemsByVariants(activityBasketItems);
};

export const getBasketItemNumberOfAddmissionDates = basketItem => {
  const {variant} = basketItem;
  const {
    numberOfAdmissionDatesMin,
    numberOfAdmissionDatesMax
  } = variant;

  return {numberOfAdmissionDatesMin, numberOfAdmissionDatesMax};
};

export const splitBasketItemsByUpsell = allBasketItems => {
  if (!allBasketItems?.length) {
    return [];
  }

  const upsellBasketItems = allBasketItems.filter(({isUpsell}) => isUpsell);
  const basketItems = allBasketItems.filter(({isUpsell}) => !isUpsell);

  return {basketItems, upsellBasketItems};
};
